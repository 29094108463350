<template>
  <div id="contents" class="contents half_price_page">
    <div class="content_head">
      <!-- s: 페이지 타이틀 -->
      <h2 class="page_ttl">반값초이스</h2>
      <!-- e: 페이지 타이틀 -->
    </div>

    <!-- s: content_body -->
    <div class="content_body">
      <!-- s: input_sec -->
      <div class="board_sec">

        <!-- Dev 수정모드일 경우에만 등록일시/수정일시 노출 -->
        <div class="tbl_wrap list form">
          <table class="tbl">
            <caption></caption>
            <colgroup>
              <col width="200"/>
              <col/>
            </colgroup>
            <tbody>
            <tr>
              <th >수정 일시</th>
              <td>
                <p>2021.07.05 12:33:56</p>
              </td>
            </tr>
            <tr>
              <th class="must_txt">URL Link</th>
              <td>
                <!-- Dev: 오류 텍스트 노출 시 input_area에 is_error 클래스 추가-->
                <div class="input_area">
                  <label></label>
                  <div class="input_box">
                    <input type="text">
                  </div>
                  <small class="error_txt" style="display: none">URL Link를 입력 해 주세요.</small>
                </div>
              </td>
            </tr>
            <tr>
              <th class="must_txt">QR 이미지</th>
              <td>
                <div class="bg_box">
                  <ul>
                    <li>
                      <span>이미지 파일형식 jpg, png</span>
                      <span>용량 1MB 이하</span>
                      <span>해상도 추후결정</span>
                    </li>
                  </ul>
                </div>
                <div class="upload_area clearFix">
                  <!-- Dev: 파일 첨부 후 is_error 클래스 삭제, video 클래스 추가 -->
                  <div class="upload_box flL is_error">

                    <!-- Dev s: 등록된 파일 없을 경우 -->
                    <div class="upload_btn" role="button">
                      <div class="txt_area">
                        <p>지정된 포맷과 사이즈의 파일을 등록해 주세요</p>
                      </div>
                      <div class="btn_area">
                        <button type="button" class="btn basic w_s h_s">파일추가</button>
                      </div>
                      <input type="file" accept=".jpg,.png">
                    </div>
                    <small class="error_txt">파일을 선택 해 주세요.</small>
                    <!-- Dev e: 등록된 파일 없을 경우 -->

                    <!-- Dev s: 등록된 파일 있을 경우 -->
                    <div class="upload_btn has_file" role="button" style="display: none">
                      <figure v-if="isImage(file)" class="uploaded_img">
                        <img :src="getFile(file)" alt="">
                      </figure>
                      <input type="file"
                             accept="image/jpeg,image/png"
                      >
                      <div class="btn_area">
                        <button type="button" class="btn w_s h_s basic">
                          다운로드
                        </button>
                        <button type="button" class="btn w_s h_s basic">
                          삭제
                        </button>
                      </div>
                      <p class="filename">파일명.png</p>
                    </div>
                    <!-- Dev e: 등록된 파일 있을 경우 -->

                  </div>
                </div>
              </td>
            </tr>
            </tbody>
          </table>
        </div>
      </div>
      <!-- e: board_sec -->
    </div>
    <!-- e: content_body -->

    <!-- s: content_foot -->
    <div class="content_foot">
      <div class="btn_group center">
        <button class="btn strong w_l h_m">발행하기</button>
      </div>
    </div>
    <!-- e: content_foot -->
  </div>

</template>

<script>

export default {
  name: "index",
  layout: {
    name: 'admin',
  },
  components: {
  },
  data() {
    return {
    }
  },
  methods: {
  },
  created() {
  },
}
</script>

<style scoped>

</style>